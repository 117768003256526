export enum HubspotChannel {
  EMAIL,
  WHATSAPP,
  SMS,
}

export enum HubspotSubscriptionStatus {
  SUBSCRIBED = 'SUBSCRIBED',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export enum HubspotSubscriptionLegalBasis {
  LEGITIMATE_INTEREST_PQL,
  LEGITIMATE_INTEREST_CLIENT,
  PERFOMANCE_OF_CONTRACT,
  CONSENT_WITH_NOTICE,
  NON_GDPR,
  PROCCESS_AND_STORE,
  LEGITIMATE_INTEREST_OTHER,
}

export type HubspotUserSubscription = {
  businessUnitId: number
  channel: HubspotChannel
  subscriberIdString: string
  subscriptionId: number
  subscriptionName: string
  status: HubspotSubscriptionStatus
  source: string
  legalBasis: HubspotSubscriptionLegalBasis
  legalBasisExplanation: string
  setStatusSuccessReason: null
  timestamp: string
}

export type HubspotSubscription = {
  businessUnitId: number
  id: string
  name: string
  description: string
  purpose: string
  communicationMethod: string
  isActive: boolean
  isDefault: boolean
  isInternal: boolean
  createdAt: string
  updatedAt: string
}

export type HubspotUserSubscriptionDto = {
  id: string
  name: string
  description: string
  status: HubspotSubscriptionStatus
}
