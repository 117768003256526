import type { ComponentProps, FC } from 'react'
import {
  ArrowRightOnRectangleIcon,
  ChartPieIcon,
  ChatBubbleOvalLeftIcon,
  HomeIcon,
  Square3Stack3DIcon,
  UserCircleIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline'

export type MenuItemType = {
  name: string
  href?: string
  icon?: FC<ComponentProps<'svg'>>
  items?: MenuItemType[]
}

export const mainNavigation: MenuItemType[] = [
  {
    name: 'Home',
    href: '/',
    icon: HomeIcon,
  },
  {
    name: 'Alternatives',
    icon: ChartPieIcon,
    items: [
      {
        name: 'Investments',
        href: '/investments',
      },
      {
        name: 'Portfolio',
        href: '/portfolio',
      },
      {
        name: 'Data Room',
        href: 'https://dataroom.anduin.app/#/dashboard/all/active',
      },
    ],
  },
  {
    name: 'Club',
    icon: ChatBubbleOvalLeftIcon,
    items: [
      {
        name: 'Articles',
        href: '/articles',
      },
      {
        name: 'Events',
        href: '/events',
      },
      {
        name: 'Groups',
        href: '/groups',
      },
      {
        name: 'Member Perks',
        href: '/member-perks',
      },
      {
        name: 'Philanthropy',
        href: '/philanthropy',
      },
    ],
  },
  {
    name: 'Wealth Channels',
    icon: Square3Stack3DIcon,
    items: [
      {
        name: 'Insurance',
        href: '/wealth-channels/insurance',
      },
    ],
  },
]

export const extraNavigation: MenuItemType[] = [
  {
    name: 'Profile',
    href: '/profile',
    icon: UserCircleIcon,
  },
  {
    name: 'Settings',
    href: '/profile/settings',
    icon: WrenchIcon,
  },
  {
    name: 'Logout',
    href: '/logout',
    icon: ArrowRightOnRectangleIcon,
  },
]
