import { useFlags } from 'launchdarkly-react-client-sdk'

export enum LDFlag {
  SocialConnections = 'social-connections',
  SocialProfileNominations = 'social-profile-nominations',
  GroupMembersPageSize = 'group-members-page-size',
  MemberSocialProfilePortfolioV2 = 'member-profile-portfolio-v-2',
  InternalTestingFeatures = 'internalTestingFeatures',
  MemberSubscriptionPortal = 'member-subscription-portal',
  EnableOnfidoKycSignup = 'enable-onfido-kyc-signup',
  EnableMemberDiscoveryNavigation = 'members-discovery-link',
  AllowInvestorToRequestAnIntroduction = 'request-an-introduction',
  MasterSwitchForRequestAnIntroduction = 'master-request-an-introduction',
  ForceApplicationUpdate = 'force-application-update',
  DisplayTotalIOIs = 'display-total-iois',
  EnableOffPisteInvestments = 'off-piste-investments',
  EnableInvestmentTypeFilter = 'enable-investment-type-filter',
  EnableAddEventToCalendar = 'add-event-to-google-calendar',
  EnableMemberDiscoverySchoolsFilter = 'member-discovery-schools-filter',
  WealthChannels = 'wealth-channels',
  DisplayNominatorsLeaderboard = 'display-nominators-leaderboard',
  CollectPhoneNumber = 'collect-phone-number',
  MostReadArticlesTimeFrame = 'most-read-articles-time-frame',
  HideObsoleteAdminPages = 'hide-obsolete-admin-pages',
  CourierNotifications = 'courier-notifications',
  DisplayNotificationsSetting = 'display-notifications-setting',
}

export const useFlag = <T>(flag: LDFlag): T => {
  const flags = useFlags() || {}
  return flags[flag]
}
